// Core
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import classNames from "classnames"

import { withTranslation } from "react-i18next"
import { compose } from "redux"

//Components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Badge from "components/Badge/Badge.js"
// import Table from "components/Table/Table";

import Pagination from "components/Pagination/Pagination.js"
// Icons

// Styles
import style from "assets/jss/material-kit-pro-react/views/contactUsStyle.js"
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js"
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

// Libs
import _ from "lodash"
import axios from "axios"
import Config from "config"
import { fnumber } from "components/Helper"
import moment from "moment"
import secureStorage from "libs/secureStorage"

import { Table, Label, Button, Modal, Transition, Dropdown } from "semantic-ui-react"
import GSearchActions from "components/GSearchActions"

import { SalesType, DirectUserType, EndUsers } from "content/User/libs"

const styles = (theme) => ({
	...modalStyle(theme),
	...style,
	...tooltipsStyle,
	buttons: {
		marginTop: "25px",
	},
	tableResponsive: {
		minHeight: "0.1%",
		overflowX: "auto",
		// overflowY: 'scroll'
	},
	table: {
		marginBottom: "0",
		width: "100%",
		maxWidth: "100%",
		backgroundColor: "transparent",
		borderSpacing: "0",
		borderCollapse: "collapse",
		overflow: "auto",
		"& > tbody > tr, & > thead > tr, & > tfoot > tr,": {
			height: "auto",
		},
	},
})

class BillHistory extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			//user : secureStorage.getItem('user'),

			// paging states >>
			rowsPerPage: 15,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			rows: [],
			details: [],
			//<<pagin states

			keyword: "",

			modalMode: "",
			modalShow: false,
			modalTitle: "",

			modalName: "",
			modalAmount: "",

			selectedBill: "",
			selectedParentBill: "",

			startDate: moment().startOf("day"),
			endDate: moment().endOf("day"),

			parentDue: "",

			billSelected: null,
			chargedIds: [],

			salesType: null,
			userTypes: [],

			info: "",
		}
	}

	componentDidMount() {
		const user = secureStorage.getItem("user")
		const info = secureStorage.getItem("domainInfo")
		this.setState({ info })

		let params = {}
		if (user.userTypeId !== 1) {
			params = {
				startDate: moment().startOf("month"),
				endDate: moment().endOf("month"),
			}
		}
		this.loadBill(params)
		const now = moment()
		const duedate = moment().subtract(1, "minutes")
		// console.log(now.isAfter(duedate));

		if (info && ["saminara.com", "abbasyonline.com"].includes(info.name)) {
			const salesType = this.initSalesType()
			this.initUserTypes({ salesType })
		}
	}

	initSalesType() {
		// ['DM 2 Levels','TL 3 Levels','H2H DM','H2H TL',"DP 3 Levels","H2H RF","FD 5 Levels","FT 5 Levels"]
		const user = secureStorage.getItem("user")
		let salesType = null
		if ([6].includes(user.userTypeId)) salesType = 0
		else if ([2, 3].includes(user.userTypeId)) salesType = 1
		else if ([5].includes(user.userTypeId)) salesType = 2
		else if ([8].includes(user.userTypeId)) salesType = 2
		else if ([9, 10].includes(user.userTypeId)) salesType = 4
		else if ([12].includes(user.userTypeId)) salesType = 5
		else if ([14, 15, 16, 17].includes(user.userTypeId)) salesType = 6
		else if ([19, 20, 21, 22].includes(user.userTypeId)) salesType = 7
		else if ([24, 25].includes(user.userTypeId)) salesType = 8
		else if ([26, 27, 28].includes(user.userTypeId)) salesType = 9
		this.setState({ salesType })
		return salesType
	}

	initUserTypes({ salesType }) {
		const user = secureStorage.getItem("user")
		axios
			.post(Config.ApiUrl + "/transaction/usertypes")
			.then((res) => {
				let userTypes = res.data
				userTypes[0].name = "Mitra"
				this.setState({ userTypes })
				// console.log({ userTypes });

				this.loadUserDropdown({
					salesType,
					parents: [user],
					userTypes,
					parentTypeId: user.userTypeId,
					init: true,
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}

	loadUserDropdown(params) {
		const user = secureStorage.getItem("user")
		const userTypes = params.userTypes ? params.userTypes : this.state.userTypes
		const salesType = params.salesType ? params.salesType : this.state.salesType
		const payload = {
			salesType,
			parents: params.parents && params.parents,
		}

		axios
			.post(Config.ApiUrl + "/transaction/userchild", payload)
			.then((res) => {
				const data = res.data
				let userTypeIdx = params.parentTypeId
				if (user.userTypeId === 1) {
					userTypeIdx = userTypeIdx - 1
				}

				// console.log({
				//   salesType,
				//   userTypes,
				//   userTypeIdx,
				//   dropdown: userTypes[userTypeIdx],
				// });
				this.setState({ [userTypes[userTypeIdx].name]: data })

				// if (params.init && user.userTypeId === 1)
				// // console.log(params.parentTypeId)
				// // console.log(userTypes)
				// this.setState({ [userTypes[userTypeIdx].name]: res.data });
			})
			.catch((error) => {
				console.log(error)
			})
	}

	getUserDropDown = (userTypeId) => {
		console.log({
			userTypeId,
			userTypesLength: this.state.userTypes.length,
			userTypes: this.state.userTypes,
			state: this.state,
		})
		if (this.state.userTypes.length > 0 && this.state[this.state.userTypes[userTypeId - 1].name])
			return (
				<GridItem xl={4} lg={4} md={4} sm={4} xs={6}>
					<Dropdown
						style={{ marginTop: 25 }}
						placeholder={this.state.userTypes[userTypeId - 1].name}
						fluid
						multiple
						search
						selection
						onChange={(e, { value }) => {
							console.log(value)
							if (value.length === 0 && this.state.userTypes[userTypeId])
								this.setState({
									[this.state.userTypes[userTypeId].name]: undefined,
									[this.state.userTypes[userTypeId].id]: undefined,
								})
							if (!EndUsers.includes(userTypeId) && value.length > 0)
								this.loadUserDropdown({
									// salesType,
									parents: this.state[this.state.userTypes[userTypeId - 1].name].filter(
										(item) => value.indexOf(item.id) !== -1
									),
									// userTypes,
									parentTypeId: userTypeId,
								})
							const val = this.state[this.state.userTypes[userTypeId - 1].name]
								.filter((item) => value.indexOf(item.id) !== -1)
								.map((item) => item)
							// console.log(val)
							this.setState({
								[this.state.userTypes[userTypeId - 1].id]: val,
							})
						}}
						options={this.state[this.state.userTypes[userTypeId - 1].name].map((item, index) => ({
							key: item.id,
							text: item.name,
							value: item.id,
						}))}
					/>
				</GridItem>
			)
	}

	loadBill = (params) => {
		const user = secureStorage.getItem("user")
		const userTypes = this.state.userTypes
		const users = _.flattenDeep(userTypes.filter((item) => this.state[item.id]).map((item) => this.state[item.id]))
		const parents = users.length > 0 ? users : [user]
		// console.log({ parents });

		const payload = {
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
			// pagination payload>>
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			// <<pagination payload
			parentId: params && params.parentId,
			isCharger: this.props.isCharger,

			parents,
		}
		axios
			.post(Config.ApiUrl + "/bill/history", payload)
			.then((res) => {
				//console.log(params &&  params.partnerDropdown?'true':'false')
				// console.log(res.data)
				const rows = !params ? { rows: res.data.bills } : !params.parentId ? { rows: res.data.bills } : {}
				const details = params && params.parentId ? { details: res.data.bills } : {}
				// console.log(res.data.bills)
				this.setState({
					...rows,
					...details,
					...payload,
					totalPages: res.data.totalPages,
					totalData: res.data.totalData,
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}

	loadChild = (params) => {
		const payload = {
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
			chargedId: params && params.chargedId ? params.chargedId : null,
		}

		axios
			.post(Config.ApiUrl + "/bill/history/child", payload)
			.then((res) => {
				const bills = this.state.rows
				let bill = bills.find((item) => item.id === params.billId)
				bill.Children = res.data
				// console.log({ resp: res.data, bill });

				this.setState({
					...bills,
					...bill,
					billSelected: params.billId,
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}

	handleSubmit = () => {
		this.loadBill()
	}

	handleDropDownChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	handleClear = (e) => {
		this.setState({
			keyword: "",
		})
		this.loadBill({ keyword: "" })
	}

	handleShowModal = () => {
		this.setState({ modalShow: true })
	}
	handleModal = (target) => {
		if (target !== "back") {
			if (this.state.modalAmount && this.state.selectedBill) {
				const payload = {
					amount: this.state.modalAmount,
					selectedBill: this.state.selectedBill,
					selectedParentBill: this.state.selectedParentBill,
				}

				axios
					.post(Config.ApiUrl + "/payment/confirm", payload)
					.then((res) => {
						this.loadBill()
						this.setState({ modalShow: false, selectedBill: "" })
					})
					.catch((err) => {
						console.log(err)
					})
			}
		} else this.setState({ modalShow: false })
	}

	renderStatus(bill) {
		const { t } = this.props
		let color
		switch (bill.statusId) {
			case 1:
				color = "rose"
				break
			case 2:
				color = "primary"
				break
			case 3:
				color = "linkedin"
				break
			case 4:
				color = "success"
				break
			case 5:
				color = "instagram"
				break
			case 6:
				color = "danger"
				break
			default:
				color = "facebook"
		}

		return <Badge color={color}>{t(bill.BillStatus.name)}</Badge>
	}
	renderButton(bill, parentBill) {
		let disabled = false
		if (bill.Splitted && bill.Splitted.length > 0) disabled = true
		else if (bill.statusId !== 2) disabled = true

		return (
			<Button
				color="instagram"
				size="sm"
				round
				disabled={disabled}
				onClick={() => {
					this.setState({
						modalMode: "confirm",
						modalShow: true,
						modalTitle: "Payment Confirmation",
						modalName: !parentBill ? bill.BillCharged.name : parentBill.BillCharged.name,
						modalAmount: bill.uniqueAmount,
						selectedBill: bill,
						selectedParentBill: parentBill,
					})
				}}
			>
				Confirm
			</Button>
		)
	}

	getDueDate = (bill, splitted) => {
		const user = secureStorage.getItem("user")
		const createdAt = splitted ? this.state.parentDue : bill.createdAt
		let dueDate
		if (bill) {
			if (moment(createdAt).weekday() === 6)
				dueDate = moment(createdAt).startOf("day").add(2, "days").add(11, "hours").add(30, "minutes")
			else if (moment(createdAt).weekday() === 0)
				dueDate = moment(createdAt).startOf("day").add(1, "days").add(11, "hours").add(30, "minutes")
			else if (bill.cycle === 2) dueDate = moment(createdAt).add(3, "hours")
			else dueDate = moment(createdAt).startOf("day").add(11, "hours").add(30, "minutes")
			if (user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory")
				dueDate = dueDate.subtract(30, "minutes")
			return dueDate.format("dddd, DD MMMM YYYY")
		}
	}

	renderRow = ({ row, index, parent, depth, isChild }) => {
		const user = secureStorage.getItem("user")
		const { classes, t } = this.props
		const children = row.Children
		const rows = [
			<Table.Row key={index} className={index % 2 === 0 ? classes.evenRow : classes.oddRow}>
				<Table.Cell>
					<span style={{ marginLeft: isChild ? depth + 10 : 0 }}>
						{index + 1}
						{
							// row.isChild ?
							// 	!this.state['expanded' + row.id] ?
							// 		<Button size='mini' circular icon="chevron circle right" style={{ marginLeft: 5 }}
							// 			onClick={() => {
							// 				const isDepth = this.state.chargedIds.includes(row.chargedId)
							// 				console.log({ isDepth })
							// 				this.setState({
							// 					['expanded' + this.state.billSelected]: false,
							// 					['expanded' + row.id]: true,
							// 				}, () => {
							// 					this.loadChild({ chargedId: row.chargedId, billId: row.id })
							// 				})
							// 			}}
							// 		/>
							// 		:
							// 		<Button size='mini' circular icon="chevron circle down" style={{ marginLeft: 5 }}
							// 			onClick={() => {
							// 				this.setState({ ['expanded' + row.id]: false })
							// 			}}
							// 		/>
							// 	: null
							children && children.length > 0 ? (
								!this.state["expanded" + row.id] ? (
									<Button
										size="mini"
										circular
										icon="chevron circle right"
										style={{ marginLeft: 5 }}
										onClick={() => {
											this.setState({ ["expanded" + row.id]: true })
										}}
									/>
								) : (
									<Button
										size="mini"
										circular
										icon="chevron circle down"
										style={{ marginLeft: 5 }}
										onClick={() => {
											this.setState({ ["expanded" + row.id]: false })
										}}
									/>
								)
							) : null
						}
					</span>
				</Table.Cell>
				<Table.Cell>{row.id.toString().padStart(6, 0)}</Table.Cell>
				<Table.Cell>{`${row.BillCharged.id.toString().padStart(6, 0)} (${row.BillCharged.UserType?.name})`}</Table.Cell>
				<Table.Cell>{row.BillCharged.name}</Table.Cell>
				<Table.Cell>{moment(row.createdAt).format("dddd, DD MMMM YYYY")}</Table.Cell>
				<Table.Cell>{this.getDueDate(row)}</Table.Cell>
				<Table.Cell>
					{row.remark === "Splitted" ? (
						<Button
							circular
							color="linkedin"
							size="mini"
							onClick={() => {
								this.setState({
									modalShow: true,
									parentDue: row.createdAt,
								})
								this.loadBill({
									parentId: row.id,
									chargerId: parent && parent.chargedId ? parent.chargedId : undefined,
								})
							}}
						>
							{t("Splitted")}
						</Button>
					) : (
						row.Bank && row.Bank.name
					)}
					{/* {row.Bank
						? row.Bank.name
						: row.remark.toString().trim() === "Splitted" && (
								<Button
									circular
									color="linkedin"
									size="mini"
									onClick={() => {
										this.setState({
											modalShow: true,
											parentDue: row.createdAt,
										})
										this.loadBill({
											parentId: row.id,
											chargerId: parent && parent.chargedId ? parent.chargedId : undefined,
										})
									}}
								>
									{t("Splitted")}
								</Button>
						  )} */}
				</Table.Cell>
				<Table.Cell textAlign="right">{fnumber(row.amount)}</Table.Cell>
				<Table.Cell textAlign="right">{fnumber(row.paidAmount ? row.paidAmount : 0)}</Table.Cell>
				<Table.Cell>{this.renderStatus(row)}</Table.Cell>
				{user.userTypeId === 1 || (user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory") ? (
					<Table.Cell>{row.LoginUser ? row.LoginUser.name : "-"}</Table.Cell>
				) : null}
			</Table.Row>,
		]

		const childs =
			children && children.length > 0 && this.state[`expanded${row.id}`]
				? children.map((item, idx) => {
						return this.renderRow({
							row: item,
							parent: row,
							index: idx,
							isChild: true,
							depth: depth + 30,
						})
				  })
				: []

		return rows.concat(childs)
	}

	renderUser = () => {
		const user = secureStorage.getItem("user")
		return (
			<GridContainer justify="center">
				{/* ADMIN */}
				{user.userTypeId === 1 && this.getUserDropDown(1)}
				{/* CATL */}
				{[2].includes(user.userTypeId) &&
					// [1].includes(this.state.salesType) &&
					this.getUserDropDown(3)}
				{[2, 3].includes(user.userTypeId) && this.getUserDropDown(4)}

				{/* MIFT */}
				{[19].includes(user.userTypeId) && this.getUserDropDown(20)}
				{[20].includes(user.userTypeId) && this.getUserDropDown(21)}
				{[20, 21].includes(user.userTypeId) && this.getUserDropDown(22)}
				{[20, 21, 22].includes(user.userTypeId) && this.getUserDropDown(23)}

				{/* MIGT */}
				{[26].includes(user.userTypeId) && this.getUserDropDown(27)}
				{[27].includes(user.userTypeId) && this.getUserDropDown(28)}
				{[27, 28].includes(user.userTypeId) && this.getUserDropDown(29)}
			</GridContainer>
		)
	}

	render() {
		const user = secureStorage.getItem("user")
		const info = secureStorage.getItem("domainInfo")
		const { classes, t } = this.props

		let allRows = []
		this.state.rows.map((row, index) => {
			allRows = allRows.concat(this.renderRow({ row, index, depth: 0 }))
			return row
		})

		return (
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
								<GridContainer justify="center">
									<GridItem xl={2} lg={2} md={2} sm={12} xs={12} className={classes.grid}>
										<Label size="big">{user.userTypeId !== 1 ? t("billHistory") : t("paymentHistory")}</Label>
									</GridItem>
									<GridItem xl={10} lg={10} md={10} sm={12} xs={12}>
										<GSearchActions
											onChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
											onSubmit={this.loadBill}
										/>
									</GridItem>
								</GridContainer>
								{["saminara.com", "abbasyonline.com"].includes(info && info.name) && this.renderUser()}
							</GridItem>
							<GridItem
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								className={classes.grid}
								style={{ textAlign: "center" }}
							>
								<div className={classes.tableResponsive}>
									<Table selectable unstackable className={classes.table}>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>#</Table.HeaderCell>
												<Table.HeaderCell>BID</Table.HeaderCell>
												<Table.HeaderCell>UID</Table.HeaderCell>
												<Table.HeaderCell>{t("name")}</Table.HeaderCell>
												<Table.HeaderCell>{t("Date")}</Table.HeaderCell>
												<Table.HeaderCell>{t("dueDate")}</Table.HeaderCell>
												<Table.HeaderCell>Bank</Table.HeaderCell>
												<Table.HeaderCell>{t("dueAmount")}</Table.HeaderCell>
												<Table.HeaderCell>{t("paidAmount")}</Table.HeaderCell>
												<Table.HeaderCell>Status</Table.HeaderCell>
												{user.userTypeId === 1 ||
												(user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory") ? (
													<Table.HeaderCell>PIC</Table.HeaderCell>
												) : null}
											</Table.Row>
										</Table.Header>
										<Table.Body>{allRows}</Table.Body>
									</Table>
								</div>
							</GridItem>
							<GridItem xl={12} lg={12} md={12} sm={12} xs={12} className={classes.grid}>
								<div className={classes.justifyContentCenter}>
									<Pagination
										totalButton={7}
										totalPages={this.state.totalPages}
										totalData={this.state.totalData}
										currentPage={this.state.currentPage}
										onChangePage={(currentPage) => {
											if (currentPage !== this.state.currentPage) {
												this.setState({ currentPage })
												this.loadBill({ currentPage })
											}
										}}
										rowsPerPage={[5, 15, 25, "All"]}
										defaultRowsPerPage={15}
										onChangeRowsPerPage={(value) => {
											this.setState({ rowsPerPage: value, currentPage: 1 })
											this.loadBill({ rowsPerPage: value, currentPage: 1 })
										}}
									/>
								</div>
							</GridItem>
						</GridContainer>
						<Transition.Group animation="drop" duration={200}>
							{this.state.modalShow && (
								<Modal
									dimmer="blurring"
									size="large"
									open={this.state.modalShow}
									onClose={() => this.setState({ modalShow: false })}
								>
									<Modal.Header>{t("billDetail")}</Modal.Header>
									<Modal.Content>
										<div className={classes.tableResponsive}>
											<Table selectable unstackable className={classes.table}>
												<Table.Header>
													<Table.Row>
														<Table.HeaderCell>#</Table.HeaderCell>
														<Table.HeaderCell>BID</Table.HeaderCell>
														<Table.HeaderCell>UID</Table.HeaderCell>
														<Table.HeaderCell>{t("name")}</Table.HeaderCell>
														<Table.HeaderCell>{t("dueDate")}</Table.HeaderCell>
														<Table.HeaderCell>Bank</Table.HeaderCell>
														<Table.HeaderCell>{t("dueAmount")}</Table.HeaderCell>
														<Table.HeaderCell>{t("paidAmount")}</Table.HeaderCell>
														<Table.HeaderCell>{t("remark")}</Table.HeaderCell>
														<Table.HeaderCell>Status</Table.HeaderCell>
														{user.userTypeId === 1 ||
														(user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory") ? (
															<Table.HeaderCell>PIC</Table.HeaderCell>
														) : null}
													</Table.Row>
												</Table.Header>
												<Table.Body>
													{this.state.details.map((bill, index) => (
														<Table.Row key={index} className={index % 2 === 0 ? classes.evenRow : classes.oddRow}>
															<Table.Cell>
																{index +
																	1 +
																	(isNaN(this.state.rowsPerPage)
																		? 0
																		: (this.state.currentPage - 1) * this.state.rowsPerPage)}
															</Table.Cell>
															<Table.Cell>{bill.id.toString().padStart(6, 0)}</Table.Cell>
															<Table.Cell>{bill.BillCharged.id.toString().padStart(6, 0)}</Table.Cell>
															<Table.Cell>{bill.BillCharged.name}</Table.Cell>
															<Table.Cell>{this.getDueDate(bill, true)}</Table.Cell>
															<Table.Cell>{bill.Bank ? bill.Bank.name : ""}</Table.Cell>
															<Table.Cell textAlign="right">{fnumber(bill.amount)}</Table.Cell>
															<Table.Cell textAlign="right">
																{fnumber(bill.paidAmount ? bill.paidAmount : 0)}
															</Table.Cell>
															<Table.Cell>{bill.remark ? bill.remark : "-"}</Table.Cell>
															<Table.Cell>{this.renderStatus(bill)}</Table.Cell>
															{user.userTypeId === 1 ||
															(user.userTypeId === 2 && this.props.location.pathname === "/PaymentHistory") ? (
																<Table.Cell>{bill.LoginUser ? bill.LoginUser.name : "-"}</Table.Cell>
															) : null}
														</Table.Row>
													))}
												</Table.Body>
											</Table>
										</div>
									</Modal.Content>
									<Modal.Actions>
										<Button negative onClick={() => this.setState({ modalShow: false })}>
											Close
										</Button>
									</Modal.Actions>
								</Modal>
							)}
						</Transition.Group>
					</div>
				</div>
			</div>
		)
	}
}
BillHistory.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default compose(withTranslation(), withStyles(styles))(BillHistory)
